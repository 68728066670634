import {
  ADD_TOAST,
  REMOVE_TOAST
} from '../constants';

const defaultOptions = {
  variant: 'info',
  vertical: 'top',
  horizontal: 'right',
  autoHideDuration: 6000
};

function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: Math.floor(Math.random() * 255)
  };
}

export function addToast(options = {}) {
  return {
    type: ADD_TOAST,
    payload: createToast(options)
  };
}

export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    payload: id
  };
}
